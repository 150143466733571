<template>
  <div>
    <v-card v-if="formObject">
      <v-card color="primary" min-height="10" flat tile class="pa-1">
        <v-card-actions class="white--text">
          <v-icon class="mr-2" color="white">mdi-form-select</v-icon>
          {{
            isEditing
              ? `Actualizando la condicion de producto "${formObject.text}"`
              : "Creando una condicion de producto"
          }}
          <v-spacer />
          <v-icon
            large
            @click="closePanel"
            class="mr-1"
            id="btn-close"
            color="white"
            >mdi-close-circle</v-icon
          >
        </v-card-actions>
      </v-card>
      <div class="px-4 py-4">
        <v-card-text class="px-6">
          <v-form v-if="formObject" v-model="valid" ref="form">
            <v-card class="pa-2" color="transparent" flat>
              <v-row align="stretch" justify="start">
                <v-col cols="12" sm="12">
                  <v-combobox
                    :id="`input-type`"
                    v-model="conditionText"
                    :items="formconditions"
                    label="Tipo de condicion de producto"
                    :rules="[
                      (v) =>
                        !!v ||
                        'El campo tipo de condicion de producto es requerido',
                    ]"
                    outlined
                    :return-object="false"
                    :disabled="isEditing"
                  />
                </v-col>
                <v-col cols="12" sm="12" v-if="conditionType">
                  <v-subheader>
                    Seleccione la opción que considere más apropiada
                  </v-subheader>
                  <v-radio-group v-model="conditionValue" row class="px-4">
                    <template v-for="(item, i) in conditionType.items">
                      <v-radio
                        :id="`radiobtn-${item.text.toLowerCase()}`"
                        :key="i"
                        :value="item.value"
                        :label="item.text"
                      />
                    </template>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-2 pb-2">
          <v-spacer />
          <v-btn @click="closePanel" class="mr-2" depressed color="transparent"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            @click="isEditing ? parseCondition(true) : parseCondition(false)"
            :disabled="
              !valid || conditionValue === null || conditionValue === undefined
            "
          >
            {{ isEditing ? "Actualizar" : "Crear" }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import conditions from "@/assets/json/productCondition.json";
import baseForm from "@/shared/utils/Form";

export default {
  name: "condition-form",
  mixins: [baseForm],
  props: ["currentConditions"],
  data: () => ({
    conditionText: "",
    isAdding: false,
    valid: false,
    subValid: false,
    conditionOperator: null,
    conditionValue: null,
    conditionType: null,
    formconditions: [],
  }),
  watch: {
    conditionText(val) {
      if (val) {
        conditions.forEach((el) => {
          if (el.text === val) {
            this.formObject.conditionCode = el.code;
            this.formObject.text = val;
            this.conditionType = Object.assign({}, el);
          }
        });
      }
    },
  },
  mounted() {
    this.conditionValue = this.formObject?.answer?.answerBoolean;
    conditions.forEach((condition) => {
      let exits = false;
      if (condition.code === this.formObject.conditionCode) {
        this.formObject.text = condition.text;
        this.formconditions.push(condition);
        this.conditionText = condition.text;
      }
      this.currentConditions.forEach((el) => {
        if (el.conditionCode === condition.code) {
          exits = true;
        }
      });
      if (!exits) {
        this.formconditions.push(condition);
      }
    });
  },
  methods: {
    updateCondition() {
      this.$emit("updateCondition", this.formObject);
    },
    addCondition() {
      this.$emit("addCondition", this.formObject);
    },
    parseCondition(isPut) {
      this.formObject.answer = {
        answerBoolean: this.conditionValue,
      };
      if (isPut) {
        this.updateCondition();
      } else {
        this.addCondition();
      }
    },
  },
};
</script>
